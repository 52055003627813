const plugin = require('tailwindcss/plugin');

module.exports = {
  mode: 'jit',
  darkMode: false, // or 'media' or 'class'
  theme: {
    screens: {
      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      '2xl': '1536px'
      // => @media (min-width: 1536px) { ... }
    },
    fontFamily: {
      // Add fonts
      sans: ['Galano', 'Montserrat', 'sans-serif']
    },
    grid: {
      gridXMarginDesktop: '96px',
      gridXMarginMobile: '24px',
      gutterXDesktop: '48px',
      gutterXMobile: '16px',
      gutterYDesktop: '48px',
      gutterYMobile: '30px'
    },
    spacing: {
      /* These values are to be used for vertical spacing mainly
       By default, these values are inherited by the:
       padding, margin, width, height, maxHeight, gap, inset, space, and translate core plugins. */

      0: '0', // 0px
      1: '0.5rem', // 8px
      2: '1rem', // 16px
      3: '1.5rem', // 24px
      4: '2rem', // 32px
      5: '2.5rem', // 40px
      6: '3rem', // 48px
      7: '3.5rem', // 56px
      8: '4rem', // 64px
      9: '5rem', // 80px
      10: '6rem', // 96px
      11: '7rem' // 112px
    },
    extend: {
      colors: {
        // Add project colors
        main: '#141414',
        secondary: '#F1D5AA',
        secondaryDark: '#E0C69D',
        darkGrey: '#272727',
        lightGrey: '#F9f9f9'
      }
    }
  },
  variants: {
    extend: {}
  },
  plugins: [
    require('tailwindcss-selection-variant'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
    plugin(function ({ addComponents, theme }) {
      addComponents({
        '.copy-2': {
          fontSize: '16px',
          lineHeight: '24px',
          '@media screen(lg)': {
            fontSize: '18px',
            lineHeight: '28px'
          }
        }
      });
    })
  ],
  corePlugins: {
    container: false
  }
};
