import getSiteSettingsQuery from '~/queries/getSiteSettingsQuery';

export const state = () => ({
  legal: [],
  bgImageNav: [],
  restaurant: [],
  socialMedia: [],
  modalPopup: [],
  staticText: {},
  sitemap: []
});

export const mutations = {
  setStaticText(state, staticText) {
    state.staticText = staticText;
  },
  setSitemap(state, sitemap) {
    state.sitemap = sitemap;
  },
  setLegal(state, legal) {
    state.legal = legal;
  },
  setBgImageNav(state, bgImageNav) {
    state.bgImageNav = bgImageNav;
  },
  setRestaurant(state, restaurant) {
    state.restaurant = restaurant;
  },
  setSocialMedia(state, socialMedia) {
    state.socialMedia = socialMedia;
  },
  setModalPopup(state, modalPopup) {
    state.modalPopup = modalPopup;
  }
};

export const getters = {
  staticText(state) {
    return state.staticText;
  },
  sitemap(state) {
    return state.sitemap;
  },
  bgImageNav(state) {
    return state.bgImageNav;
  },
  legal(state) {
    return state.legal;
  },
  restaurant(state) {
    return state.restaurant;
  },
  socialMedia(state) {
    return state.socialMedia;
  },
  modalPopup(state) {
    return state.modalPopup;
  },
  getStaticTextByKey: (state) => (key) => {
    return state.staticText?.find(({ entry }) => entry.key === key);
  },
  getSlugByRef: (state) => (_ref) => {
    return state.sitemap.find((page) => page._id === _ref);
  }
};

export const actions = {
  async load({ commit, rootState }) {
    const locale = rootState.navigation.locale;
    const query = getSiteSettingsQuery(locale);
    const result = await this.$sanity.fetch(query);

    commit('setSitemap', result?.sitemap ?? []);
    commit('setStaticText', result?.staticText ?? []);
    commit('setLegal', result?.legal ?? []);
    commit('setRestaurant', result?.restaurant ?? []);
    commit('setBgImageNav', result?.bgImageNav?.data ?? []);
    commit('setSocialMedia', result?.socialMedia ?? []);
    commit('setModalPopup', result?.modalPopup ?? []);
  }
};
