
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      translations: 'page/getTranslations'
    }),
    pageTranslationsUrls() {
      if (!this.translations) {
        return [];
      }

      return this.$i18n.locales
        .map((locale) => {
          const { url, isHome } = this.translations[locale.code] || {};
          // const homepage = [{ de: '/' }, { fr: '/fr' }];
          // if no slug is set, then redirect to homepage of same language
          const slug = url?.pageSlug ? `${isHome ? '/' : '/' + url?.pageSlug}` : '/';
          return {
            code: locale.code,
            slug
          };
        })
        .reverse();
    }
  }
};
