const sanityClient = require('@sanity/client');

export default async function ({ route, isHMR, $config, store }) {
  try {
    if (isHMR) { return; }
    const {
      query: { preview, documentId }
    } = route;

    if (!preview) {
      store.commit('preview/setPreviewMode', false);
      return;
    }

    const client = sanityClient({
      projectId: $config.api.projectId,
      dataset: $config.api.dataset,
      apiVersion: $config.api.apiVersion,
      useCdn: false,
      token:
        // eslint-disable-next-line
        'skoKDQIihw1h1ueRvJViCaaiKzDoVnfLDG4mS2ItQmg2sJRsl3pK2wMhCSwtNifdonkC2qSX1nkwbxGuhTpR0UraDSIKesMebFHg0sW6KRyj6oAupTRqTR9Anvx6ZONWo3tcwHU5A7K6r9xu2rDVVRAv3jpT0DhKqNtLrhUij65iWa1iMuN6'
    });

    const query = `*[_id=="${documentId}"][0]`;
    const result = await client.fetch(query);
    store.commit('preview/setPreviewMode', true);
    store.commit('preview/setDocumentData', result || {});
  } catch (e) {
    throw new Error(`previewMode.js: ${e}`);
  }
}
