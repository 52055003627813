import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=8c65cc32&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeader: require('/vercel/path0/components/page/Header.vue').default,Heading: require('/vercel/path0/components/elements/Heading.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default})
