import { render, staticRenderFns } from "./default.vue?vue&type=template&id=413f0104&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageTransitionElements: require('/vercel/path0/components/page/TransitionElements.vue').default,MenuOverlay: require('/vercel/path0/components/elements/MenuOverlay.vue').default,PageCookie: require('/vercel/path0/components/page/Cookie.vue').default})
