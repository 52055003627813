
import { mapGetters } from 'vuex';
import SVGlogoWhite from '../../assets/svg/logoWhite.svg';
import SVGlogoBlack from '../../assets/svg/logoBlack.svg';
// import SVGhamburger from '../../assets/svg/hamburger.svg';

export default {
  name: 'HeaderComponent',
  components: {
    SVGlogoWhite,
    SVGlogoBlack
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive',
      isLogoWhite: 'ui/isLogoWhite',
      locale: 'navigation/locale'
    })
  },

  methods: {
    toggleNavOverlay() {
      this.$store.commit('ui/toggleOverlay', !this.isOverlayActive);
    }
  }
};
