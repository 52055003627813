
import { mapGetters } from 'vuex';
import SVGLogo from '../../assets/svg/logoRialto.svg';

export default {
  name: 'FooterComponent',
  components: {
    SVGLogo
  },
  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive',
      footer: 'settings/footer',
      legal: 'settings/legal',
      socialMedia: 'settings/socialMedia'
    })
  }
};
