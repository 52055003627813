var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "z-0 flex flex-col w-full min-h-screen bg-white"
  }, [_c('PageTransitionElements'), _vm._v(" "), _c('MenuOverlay'), _vm._v(" "), _c('Nuxt'), _vm._v(" "), _vm.$config.modules.cookieConsent.active ? _c('PageCookie', {
    attrs: {
      "position": _vm.$config.modules.cookieConsent.position,
      "transition": _vm.$config.modules.cookieConsent.transition
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }