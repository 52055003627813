import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=8a8ab15e&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=8a8ab15e&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/elements/Button.vue').default,BookingBtns: require('/vercel/path0/components/elements/BookingBtns.vue').default,AnimationCustomFadeIn: require('/vercel/path0/components/elements/Animations/CustomFadeIn.vue').default})
