
import { mapGetters } from 'vuex';
import SVGfacebook from '~/assets/svg/facebook.svg';
import SVGinstagram from '~/assets/svg/instagram.svg';
import SVGlinkedin from '~/assets/svg/linkedin.svg';

export default {
  components: {
    SVGfacebook,
    SVGinstagram,
    SVGlinkedin
  },
  computed: {
    ...mapGetters({
      socialMedia: 'settings/socialMedia'
    })
  }
};
