export default {
  methods: {
    $convertDateToString(date, locale = 'de') {
      const toParse = new Date(date);
      const day = toParse.getDate();
      const month = toParse.getMonth() + 1;
      const year = toParse.getFullYear();
      return `${day}.${month}.${year}`;
    },
    $convertDateToStringCustom(startDate, endDate, locale = 'de-DE') {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const isSameYear = start.getFullYear() === end.getFullYear();
      const isSameMonth = start.getMonth() === end.getMonth();
      let startDateOptions;

      switch (true) {
        case !isSameYear:
          startDateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
          break;
        case isSameYear && !isSameMonth:
          startDateOptions = { day: 'numeric', month: 'long' };
          break;
        case isSameYear && isSameMonth:
          startDateOptions = { day: 'numeric' };
          break;
      }

      const startDateString = start.toLocaleDateString('de-DE', startDateOptions);
      // generating end date string
      const endDateString = end.toLocaleDateString('de-DE', {
        day: 'numeric',
        year: 'numeric',
        month: 'long'
      });

      if (isSameYear && isSameMonth) {
        return startDateString + '. bis ' + endDateString;
      }

      return startDateString + ' bis ' + endDateString;
    }
  }
};
