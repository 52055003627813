import { groq } from '@nuxtjs/sanity';

/**
 * With this query, we get all the content from the Sanity -> Settings
 */

export default function getSiteSettingsQuery(locale) {
  return groq`*[_type=="page" && __i18n_lang=="${locale}"][0] {
    "socialMedia":  *[_type=="socialMedia"][0] { list },
    "restaurant":  *[_type=="restaurant" && __i18n_lang=="${locale}"][0],
    "modalPopup":  *[_type=="modalPopup"][0],
    "sitemap": *[_type=="page" &&
      __i18n_lang=="${locale}" &&
      !(_id in path("drafts.**"))
    ] { url, _id, __i18n_lang, live },
    "legal": *[
      _type=="legal" &&
      __i18n_lang=="${locale}"
    ][0] { conditions->{title, url}, imprint->{title, url}, privacy->{title, url} },
    "bgImageNav": *[
      menuId=="header" &&
      _type=="menu" &&
      __i18n_lang=="${locale}"
    ][0] {"data": navigation[].pageRef->{overlayPicture, "url": url}},
    "staticText": *[_type=="staticText" && __i18n_lang=="${locale}"] { entry, _id }
  }`;
}
