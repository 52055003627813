
import { mapGetters } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'settings/restaurant'
    }),
    hideBar() {
      // TODO: update this with the actual URLs once the project is done
      return this.$route.params.pathMatch === 'reservation';
    }
  }
};
