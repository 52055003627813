
import { mapGetters } from 'vuex';

export default {
  name: 'MenuOverlay',
  data() {
    return {
      activeBg: null,
      defaultBg: null,
      renderImage: false
    };
  },
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive',
      bgImageNav: 'settings/bgImageNav',
      restaurant: 'settings/restaurant'
    }),

    localePrefix() {
      return this.$i18n.locale === 'de' ? '' : `/${this.$i18n.locale}`;
    }
  },

  watch: {
    isOverlayActive() {
      document.documentElement.classList[this.isOverlayActive ? 'add' : 'remove']('no-scroll');
    },

    $route() {
      this.closeOverlay();
      this.detectBackgroundImage();
    }
  },

  beforeMount() {
    document.addEventListener('keyup', this.closeOverlayEsc);
  },

  mounted() {
    this.detectBackgroundImage();
    this.triggerImageRender();
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.closeOverlayEsc);
  },

  methods: {
    mouseOver(link) {
      const match = this.bgImageNav.find((p) => p.url.pageSlug === link.to);
      if (match) {
        this.renderImage = false;
        this.activeBg = match.overlayPicture.refImage._ref;
        this.triggerImageRender();
      }
    },
    mouseLeave() {
      this.renderImage = false;
      this.activeBg = this.defaultBg;
      this.triggerImageRender();
    },
    closeOverlay() {
      this.$store.commit('ui/toggleOverlay', false);
    },

    closeOverlayEsc(e) {
      if (this.active && e.keyCode === 27) {
        this.$store.commit('ui/toggleOverlay', false);
      }
    },
    triggerImageRender() {
      this.$nextTick(() => (this.renderImage = true));
    },
    detectBackgroundImage() {
      const activePath = this.$route.params.pathMatch;
      const isHome = activePath === '';

      if (isHome) {
        this.setDefaultBackground();
      } else {
        const match = this.bgImageNav.find((p) => p.url.pageSlug === activePath);
        if (match) {
          this.activeBg = match.overlayPicture.refImage._ref;
          this.defaultBg = this.activeBg;
        } else {
          this.setDefaultBackground();
        }
      }
    },
    setDefaultBackground() {
      this.defaultBg = this.restaurant.defaultMenuBg.refImage._ref;
      this.activeBg = this.defaultBg;
    }
  }
};
